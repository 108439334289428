import React from "react"
import { Button, Layout, Row, Col } from 'antd'
import SiteLayout from "../components/SiteLayout";
import YourData from "../components/Sections/YourData";
import JoinUs from "../components/Sections/JoinUs";

// @ts-ignore
import styles from './oss.module.scss';
import { StaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import ThisIsUs from "../components/Sections/ThisIsUs";



export default () => (
    <StaticQuery
        query={graphql`
        {
            teaminaction: file(relativePath: {eq: "teamaction4.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }

             reading: file(relativePath: {eq: "reading.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }

             moped: file(relativePath: {eq: "moped.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid_noBase64
                 }
               }
             }
           }           
      `
        }
        render={data => (
            <SiteLayout>
                <div>
                    <div className={styles.pagecontainer}>
                        <div className={styles.textcontainer}>
                            <div className={styles.header}>Om Oss</div>

                            <div className={styles.introtext}>
                                Historien om Oss begynner med de nye målerne, og Harald, som umiddelbart ser et potensiale i dem. De nye målerne skal nemlig gjøre informasjonen som sendes til nettselskapet ditt tilgjengelig for deg&nbsp;også.
                            </div>

                            <div className={styles.imagecontainer}>
                                <Img title="Oss-Testbruker" fluid={data.reading.childImageSharp.fluid} className={styles.image} alt="Oss testbruker" />
                            </div>

                            <div className={styles.introsubtext}>
                                Problemet er bare, i følge Harald, at denne informasjonen ikke er like tilgjengelig for alle. Det finnes ikke en enkel måte å få tilgang til disse dataene på, og de vil bare gi mening for de som er ekstremt opptatt av strøm.
                            </div>

                           

                            <div className={styles.subheader}>
                                Vår teknologi, din trygghet
                            </div>
                            <div className={styles.introsubtext}>
                                Oss har utviklet en ny teknologi som gjør at informasjonen fra den nye, automatiske strømmåleren din blir gjort tilgjengelig for deg på en enkel måte. Oss-brikken hjelper deg med å hente ut informasjon fra strømmåleren, og Oss-appen oversetter og tolker denne informasjonen for deg.
                                <br /><br />
                                Vi gjør det enkelt for deg å følge med på hvor mye strøm du har brukt tidligere, og hvor mye strøm du bruker akkurat nå, uansett om du sjekker appen hjemme i Drammen eller borte i Dublin. <strong>Sånn a propos Drammen: Både hardware og software utvikles og produseres her i Norge.</strong>
                            </div>
                            

                            <div className={styles.subheader}>
                                Skyhøye ambisjoner
                            </div>
                            <div className={styles.introsubtext}>
                                Det som er spennende er at vi fremdeles ikke helt vet hva Oss vil være om ett år. Til det trenger vi dine tilbakemeldinger, og det er nettopp det som er så spennende. Videreutviklingen av Oss skal skje i samspill med brukerne, og det er dere som avgjør hvilke tjenester vi etterhvert vil levere. Hvilke behov har dere, og hva er det dere er bekymret for? <strong>Én ting vi allerede vet er dette: ambisjonsnivået vårt er skyhøyt.</strong>
                            </div>
                        </div>
                    </div>
                    
                    <YourData topVisual={true} moreButton={true} />
                    <JoinUs moreButton={true} />
                </div>
            </SiteLayout>
        )}
    />
)

/*
 <Img title="Oss-Team" fluid={data.teaminaction.childImageSharp.fluid} style={{maxHeight: 550, minHeight: 150}}/>
                        <div style={{ position: "absolute", zIndex: 1, marginTop: "-8%", width: "100%", minWidth: 296 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 245.312" preserveAspectRatio="none">
                                <path style={{ fill: "#FFF" }} d="M0,135.769C2502.85,433.277,1634.584,30.456,1920,30.456V12.027H0Z" transform="translate(1920 245.302) rotate(180)" />
                            </svg>
                        </div>*/